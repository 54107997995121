.container {
  width: 100%;
  background-color: #ffffff;
  padding-top: 40px;
  padding-bottom: 16px;

  .infoTitle {
    height: 28px;
    font-size: 18px;
    color: #202124;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    font-weight: 500;
    img {
      width: 24px;
      height: 24px;
      margin-left: 16px;
    }
  }

  .content {
    width: calc(100% - 48px);
    max-width: 1296px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .contentLeft {
      width: 100%;
      color: #5f6368;

      .swiperContainer {
        width: 100%;
        position: relative;
        .navigationNext {
          display: none;
          width: 56px;
          height: 56px;
          position: absolute;
          right: -28px;
          top: 50%;
          transform: translateY(-50%);
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          z-index: 9;
          border-radius: 50%;
          box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
            0 2px 6px 2px rgba(60, 64, 67, 0.15);
          cursor: pointer;
          &:hover {
            background-color: #f7f7f7;
          }
        }
        .swiperContent {
          width: 100%;
          height: 100%;
          overflow: hidden;

          .swiperImg {
            height: 200px;
            object-fit: contain;
            border-radius: 8px;
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
              0 1px 3px 1px rgba(60, 64, 67, 0.15);
            cursor: pointer;
          }
        }
      }
      .contentGameInfo {
        padding-top: 20px;
        .contentGameInfoText {
          margin-top: 20px;
          color: #5f6368;
          font-family: Roboto;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
        }
      }
      .contentUpdateTime {
        margin-top: 24px;
        .contentUpdateTimeTitle {
          font-weight: 500;
          color: #202124;
        }
        .contentUpdateTimeContent {
          margin-top: 4px;
        }
      }
      .contentTags {
        margin-top: 28px;
        height: 32px;
        display: flex;
        align-items: center;
        .contentTagItem {
          margin-right: 12px;
          height: 100%;
          padding: 0 15px;
          border-radius: 16px;
          border: 1px solid rgb(218, 220, 224);
          display: flex;
          align-items: center;
          color: rgb(95, 99, 104);
          font-weight: 500;
        }
      }
      .contentSafe {
        margin-top: 24px;
        .contentSafeText {
          margin-top: 20px;
          color: #5f6368;
          font-family: Roboto;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
        }
        .contentSafeTable {
          width: 100%;
          margin-top: 30px;
          padding: 20px;
          padding-bottom: 18px;
          border: 1px solid rgb(218, 220, 224);
          border-radius: 8px;
          .contentSafeTableItem {
            display: flex;
            color: #5f6368;
            font-family: Roboto;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            margin-bottom: 20px;
            .contentSafeTableItemDesc {
              font-size: 12px;
              letter-spacing: 0.3px;
              line-height: 16px;
            }
            img {
              width: 20px;
              height: 20px;
              margin-right: 20px;
            }
          }
          .contentSafeTableDetail {
            margin-top: -2px;
            padding-left: 8px;
            color: #01875f;
            font-weight: 500;
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            // font-family: Roboto;
            letter-spacing: 0.15px;
          }
        }
      }
      .contentRate {
        margin-top: 20px;
        color: #5f6368;
        .contentRateTitle {
          display: block;
          flex-wrap: wrap;
          cursor: pointer;
          .infoTitle {
            height: 24px;
          }
          .contentRateTitleR {
            height: 52px;
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.128571px;
            color: #5f6368;
            img {
              width: 16px;
              height: 16px;
              margin-left: 8px;
            }
          }
        }
        .contentRateDevice {
          height: 32px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          .contentRateDeviceItem {
            height: 100%;
            margin-right: 12px;
            background-color: #e9f3ef;
            padding-left: 17px;
            padding-right: 21px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #056049;
            font-weight: 500;
            cursor: pointer;
            letter-spacing: 0.25px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
          .contentRateDeviceItemPc {
            height: 100%;
            margin-right: 12px;
            background-color: transparent;
            padding: 0 16px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #5f6368;
            font-weight: 500;
            border: 1px solid #d5d7d8;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
        }
        .contentRateDetail {
          margin-top: 42px;
          width: 100%;
          display: flex;
          .contentRateDetailL {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .contentRateDetailLNum {
              font-size: 56px;
              color: #202124;
              line-height: 64px;
            }
            .contentRateDetailLStar {
              height: 21.5px;
              display: flex;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .contentRateDetailLNumUser {
              margin-top: 8px;
              font-family: Roboto;
              font-size: 12px;
              color: #5f6368;
              letter-spacing: 0.3px;
              line-height: 16px;
            }
            .contentRateDetailEvaluate {
              margin-top: 8px;
              font-size: 12px;
              line-height: 16px;
            }
          }
          .contentRateDetailR {
            flex: 1;
            padding-left: 24px;
            .contentRateDetailRItem {
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 2px;
              .contentRateDetailRItemNum {
                font-size: 12px;
                margin-right: 12px;
                flex-shrink: 0;
              }
              .contentRateDetailRItemProgress {
                flex: 1;
                height: 10px;
                background-color: #e8eaed;
                border-radius: 5px;

                .contentRateDetailRItemProgressInner {
                  width: 90%;
                  height: 100%;
                  background-color: #01875f;
                  border-radius: 5px;
                }
              }
            }
          }
        }
        .contentRateEvaluate {
          margin-top: 40px;
          .contentRateEvaluateItem {
            margin-bottom: 40px;
            .contentRateEvaluateItemTitle {
              height: 32px;
              display: flex;
              align-items: center;
              .contentRateEvaluateItemTitleAvatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                flex-shrink: 0;
                cursor: pointer;
              }
              .contentRateEvaluateItemTitleName {
                flex: 1;
                padding-left: 16px;
                padding-right: 16px;
                color: #000;
                font-family: Roboto;
                line-height: 20px;
                letter-spacing: 0.2px;
              }
              .contentRateEvaluateItemTitleMore {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }
            .contentRateEvaluateItemRate {
              height: 20px;
              margin-top: 24px;
              display: flex;
              align-items: center;
              img {
                width: 12px;
                height: 12px;
              }
              div {
                font-size: 12px;
                padding-left: 7px;
                font-family: Roboto;
                letter-spacing: 0.3px;
                line-height: 16px;
                color: #5f6368;
              }
            }
            .contentRateEvaluateItemContent {
              margin-top: 8px;
              font-family: Roboto;
              color: #5f6368;
              line-height: 20px;
              letter-spacing: 0.2px;
            }
            .contentRateEvaluateItemUserful {
              margin-top: 16px;
              font-size: 12px;
              font-family: Roboto;
              color: #5f6368;
              line-height: 16px;
              letter-spacing: 0.3px;
            }
            .contentRateEvaluateItemIsUserful {
              margin-top: 12px;
              display: flex;
              align-items: center;
              font-family: Roboto;
              font-size: 12px;
              color: #5f6368;
              line-height: 16px;
              letter-spacing: 0.3px;
              .contentRateEvaluateItemIsUserfulText {
                font-size: 12px;
                margin-right: 24px;
              }
              .contentRateEvaluateItemIsUserfulBtn {
                font-family: Google Sans;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 12px;
                padding-right: 12px;
                font-size: 14px;
                border: 1px solid #d5d7d8;
                border-radius: 12px;
                margin-right: 12px;
                cursor: pointer;
                font-weight: 500;
                line-height: 10px;
                letter-spacing: 0.25px;
              }
            }
          }
          .contentRateEvaluateMore {
            color: #01875f;
            font-weight: 500;
            cursor: pointer;
            font-family: Google Sans;
            letter-spacing: 0.15px;
            padding-left: 8px;
            line-height: 20px;
          }
        }
      }
      .contentNew {
        margin-top: 38px;
        cursor: pointer;
        font-family: Google Sans;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        .contentNewContent {
          margin-top: 20px;
          color: #5f6368;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 0.2px;
          line-height: 20px;
        }
      }
    }
    .contentRight {
      flex-shrink: 0;
      margin-top: 20px;
      flex-shrink: 0;
      width: 100%;
      .contentRightOtherGameList {
        padding-top: 20px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 24px;
      }
      .contentRightGameItem {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .contentRightGameItemImg {
          width: 56px;
          height: 56px;
          flex-shrink: 0;
          border-radius: 12px;
          box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
            0 1px 3px 1px rgba(60, 64, 67, 0.15);
        }
        .contentRightGameItemImfo {
          flex: 1;
          padding-left: 16px;

          .contentRightGameItemImfoTitle {
            color: #202124;
            height: 20px;
            line-height: 20px;
            overflow: hidden;
            font-family: Roboto;
          }
          .contentRightGameItemImfoDesc {
            font-size: 12px;
            color: #202124;
            height: 16px;
            line-height: 16px;
            overflow: hidden;
            font-family: Roboto;
          }
          .contentRightGameItemImfoStar {
            display: flex;
            align-items: center;
            color: #5f6368;
            font-size: 12px;
            height: 16px;
            overflow: hidden;
            font-family: Roboto;
            img {
              margin-left: 4px;
              width: 10px;
              height: 10px;
            }
          }
        }
      }
      .contentRightOther {
        margin-top: 20px;
      }
    }
  }
  .report {
    width: calc(100% - 48px);
    max-width: 1296px;
    height: 20px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    color: #202124;
    font-weight: 500;
    cursor: pointer;
    font-family: Google Sans;
    letter-spacing: 0.25px;
    line-height: 20px;

    img {
      width: 18px;
      height: 18px;
      margin-right: 16px;
    }
  }
  .footer {
    width: calc(100% - 48px);
    max-width: 1296px;
    margin: 36px auto 0;
    .divider {
      width: 100%;
      height: 1px;
      background-color: #e8eaed;
    }
    .footerInfo {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      color: #5f6368;

      .footerInfoItem {
        width: 244px;
        // margin-right: 16px;
        font-family: Google Sans;
        .footerInfoItemTitle {
          font-weight: 500;
          cursor: pointer;
          letter-spacing: 0.25px;
          line-height: 32px;
        }
        .footerInfoItemText {
          cursor: pointer;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: 32px;
        }
      }
    }
  }
  .desc {
    width: calc(100% - 48px);
    max-width: 1296px;
    margin: 32px auto 0;
    display: flex;
    align-items: center;
    color: #5f6368;
    font-size: 12px;
    flex-wrap: wrap;
    line-height: 48px;
    .descItem {
      margin-right: 24px;
      cursor: pointer;
    }
    .descItemCountry {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 18px;
        margin-right: 12px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 660px) {
  .container {
    .infoTitle {
      font-size: 22px;
      justify-content: start;
    }
    .content {
      flex-direction: row;
      width: calc(100% - 96px);
      gap: 72px;
      .contentLeft {
        width: calc(100% - 364px - 72px);
        .swiperContainer {
          width: 100%;
          position: relative;
          .navigationNext {
            display: flex;
          }
          .swiperContent {
            .swiperImg {
              height: 300px;
            }
          }
        }
        .contentGameInfo {
          margin-top: 44px;
        }
        .contentSafe {
          margin-top: 44px;
        }
        .contentRate {
          margin-top: 44px;
          .contentRateTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .contentNew {
          margin-top: 44px;
        }
      }
      .contentRight {
        flex-shrink: 0;
        width: 364px;
        .contentRightOther {
          margin-top: 44px;
        }
        .contentRightOtherGameList {
          grid-template-columns: auto;
        }
        .contentRightGameItem {
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          .contentRightGameItemImg {
            width: 56px;
            height: 56px;
            flex-shrink: 0;
            border-radius: 12px;
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
              0 1px 3px 1px rgba(60, 64, 67, 0.15);
          }
          .contentRightGameItemImfo {
            flex: 1;
            padding-left: 16px;

            .contentRightGameItemImfoTitle {
              color: #202124;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              overflow: hidden;
              font-family: Google Sans;
            }
            .contentRightGameItemImfoDesc {
              font-size: 14px;
              color: #202124;
              height: 20px;
              line-height: 20px;
              overflow: hidden;
              font-family: Google Sans;
            }
            .contentRightGameItemImfoStar {
              display: flex;
              align-items: center;
              color: #5f6368;
              font-size: 14px;
              overflow: hidden;
              font-family: Google Sans;
            }
          }
        }
      }
    }
    .report {
      margin-top: 40px;
      width: calc(100% - 96px);
    }
    .footer {
      width: calc(100% - 96px);
      max-width: 1296px;
      margin: 60px auto 0;
      .footerInfo {
        flex-direction: row;
        gap: 24px;
        .footerInfoItem {
          width: 266px;
        }
      }
    }
    .desc {
      width: calc(100% - 96px);
      .descItem {
        margin-right: 32px;
      }
      .descItemCountry {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
