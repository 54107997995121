.container {
  padding-bottom: 32px;
  position: relative;
  .content {
    width: calc(100% - 48px);
    max-width: 1296px;
    margin: 0 auto;
    position: relative;
    .contentInfo {
      display: flex;
      flex-direction: column;
      padding-top: 48px;
      z-index: 2;
      .infoTitle {
        display: flex;
        .infoTitleImg {
          width: 72px;
          height: 72px;
          border-radius: 20%;
          margin: 7px 24px 0 0;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;
        }
        .infoTitleContent {
          .infoName {
            color: #202124;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: normal;
            font-stretch: 100%;
            text-size-adjust: 100%;
            vertical-align: baseline;
          }
          .infoDesc {
            margin-top: 2px;
            font-size: 16px;
            color: #01875f;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
          }
          .infoDesc2 {
            color: #5f6368;
            font-size: 12px;
            letter-spacing: 0.3px;
            font-family: Roboto;
            unicode-bidi: isolate;
            line-height: 16px;
          }
        }
      }
      .infoDownLoad {
        margin: 16px 0 0 0;
        display: flex;
        align-items: center;
        padding-top: 12px;
        transform: translateY(2px);
        .infoDownLoadItem {
          margin-right: 22px;
          margin-left: 22px;
          height: 44px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .infoDownLoadItemName {
            height: 20px;
            font-size: 14px;
            color: #202124;
            font-weight: 500;
            display: flex;
            justify-content: center;
            letter-spacing: 0.25px;
            line-height: 20px;
            img {
              width: 12px;
              height: 12px;
              margin-top: 3px;
              // margin-left: 4px;
            }
            .infoDownLoadItemName12 {
              width: 16px;
              height: 16px;
            }
          }
          .infoDownLoadItemDesc {
            margin-top: 4px;
            height: 20px;
            font-size: 12px;
            color: #5f6368;
            display: flex;
            // align-items: center;
            justify-content: center;
            font-family: Roboto;
            list-style-position: outside;
            letter-spacing: 0.3px;
            line-height: 16px;
            padding: 0 2px;
            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
            }
          }
        }
        .infoDownLoadItem:nth-child(1) {
          margin-right: 16px;
          margin-left: 0;
        }
        .infoDownLoadItem:last-child {
          margin-right: 0;
        }
        .infoDownLoadItemgameImgImg {
          display: none;
        }
        .infoDownLoadDivider {
          width: 1px;
          height: 24px;
          background-color: #e8eaed;
          flex-shrink: 0;
        }
      }
      .gameImgBig {
        width: 240px;
        height: 240px;
        border-radius: 20%;
        display: none;
        position: relative;
        .gameImgBig1 {
          border-radius: 20%;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
        .gameImgBig2 {
          border-radius: 20%;
          width: 200px;
          height: 200px;
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%);
          z-index: 1;
          fill: rgb(32, 33, 36);
          filter: blur(10px);
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
      }
      .infoHandler {
        margin-top: 38px;
        display: flex;
        flex-direction: column;
        .infoHandlerDownload {
          width: 100%;
          height: 36px;
          background-color: #01875f;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
          font-weight: 500;
          letter-spacing: 0.25px;
        }
        .infoHandlerShare {
          margin-top: 8px;
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #01875f;
          font-weight: 500;
          gap: 8px;
          .infoHandlerShareItem {
            padding-left: 8px;
            padding-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.15px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }
      .infoDevice {
        margin-top: 18px;
        height: 28px;
        display: flex;
        align-items: center;
        color: #5f6368;
        line-height: 20px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }
    }
  }
}

// PC
@media only screen and (min-width: 600px) {
  .container {
    .content {
      width: calc(100% - 96px);
      .contentEmpty {
        width: 100%;
        height: 112px;
      }
      .contentInfo {
        padding-top: 48px;

        .infoTitle {
          .infoTitleImg {
            width: 96px;
            height: 96px;
          }
          .infoTitleContent {
            .infoName {
              font-family: Google Sans Display;
              font-size: 56px;
              line-height: 64px;
              letter-spacing: normal;
            }
            .infoDesc {
              margin-top: 16px;
            }
          }
        }
        .infoDownLoad {
          padding-top: 12px;
          width: 320px;
        }
        .contentInfoOut {
          display: flex;
          justify-content: space-between;
        }
        .gameImgBig {
          display: block;
        }
        .infoHandler {
          margin-top: -14px;
          flex-direction: row;
          align-items: center;
          .infoHandlerDownload {
            width: 200px;
            height: 44px;
            padding-left: 70px;
            padding-right: 70px;
            font-size: 16px;
            letter-spacing: 0.1px;
          }
          .infoHandlerShare {
            margin-left: 16px;
            margin-top: 0;
            width: auto;
            gap: 16px;
            .infoHandlerShareItem {
              padding-right: 0;
              .infoHandlerShareItemName {
                display: none;
              }
            }
          }
        }
        .infoDevice {
          letter-spacing: 0.2px;
        }
      }
    }
  }
}

@media only screen and (min-width: 960px) {
  .container {
    .content {
      .contentInfo {
        padding-top: 48px;

        .infoTitle {
          .infoTitleContent {
            .infoDesc {
              margin-top: 16px;
            }
          }
          .infoTitleImg {
            display: none;
          }
        }
        .infoDownLoad {
          .infoDownLoadItemgameImgImg {
            display: block;
            width: 48px;
            height: 48px;
            margin-right: 16px;
            border-radius: 20%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    .contentVideo {
      width: 832px;
      height: 468px;
      #contentVideo {
        width: 100%;
        z-index: 0;
      }
    }
    .content {
      .contentEmpty {
        width: 100%;
        height: 112px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    .contentVideo {
      width: 947px;
      height: 532px;
    }
    .content {
      .contentEmpty {
        width: 100%;
        height: 112px;
      }
      .contentInfo {
        padding-top: 48px;

        .infoTitle {
          .infoTitleContent {
            .infoName {
              font-size: 56px;
            }
          }
        }
        .infoHandler {
          .infoHandlerShare {
            .infoHandlerShareItem {
              .infoHandlerShareItemName {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1296px) {
  .container {
    .contentVideo {
      right: calc((100vw - 1296px) / 2);
      .contentVideoContent {
        .contentVideoMask {
          background: linear-gradient(
              to right,
              rgb(32, 33, 36) 0,
              rgba(0, 0, 0, 0) 56%
            ),
            linear-gradient(to top, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%),
            linear-gradient(to left, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%);
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    .contentVideo {
      width: 1067px;
      height: 600px;
    }
    .content {
      .contentEmpty {
        width: 100%;
        height: 112px;
      }
    }
  }
}
